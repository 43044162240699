import React, { useState } from "react";
import ToggleSwitch from "../../toggleswitch/toggleSwitch";
import "../../styles/Simulation/simulation.css";
// import axiosInstance from "../../interceptors/axios";
import Simulation from "./Toggle/Simulation";
import Mail from "./Toggle/Email";

function App() {
  const [isSimulation, setIsSimulation] = useState(true);
  // const [responseData, setResponseData] = useState(null);  // Change to null initially

  const toggleSimulation = () => {
    setIsSimulation(!isSimulation);
  };


  return (
    <div>
      <ToggleSwitch active={!isSimulation} onClick={toggleSimulation} />
      {isSimulation ? (
        <Simulation />
      ) : (
        <Mail />
      )}
    </div>
  );
}

export default App;