import React from 'react';
// import logo from "../../../assets/cmpny_logo.png";
import facebookLogo from "../../../assets/facebook.svg"
import twitterLogo from "../../../assets/twitter.svg"
import instagramLogo from "../../../assets/instagram.svg"
import linkedinLogo from "../../../assets/linkedin.svg"
import youtubeLogo from "../../../assets/youtube.svg"
import "../../../styles/footer/companySocials.css"

function CompanySocials() {
    const socials = [
        {id: 1, mediaName: "faceBook", link: "https://www.facebook.com/", logo: facebookLogo },
        {id: 2, mediaName: "twitter", link: "/", logo: twitterLogo },
        {id: 3, mediaName: "linkedin", link: "/", logo: linkedinLogo },
        {id: 4, mediaName: "instagram", link: "/", logo: instagramLogo },
        {id: 5, mediaName: "youtube", link: "/", logo: youtubeLogo },
    ]

  return (
    <div className='cmpny-socials-container'>
        {/* <img src={logo} className='cmpny-socials-logo' alt='logo' />
        <h4 className='cmpny-tagline'>Random 2 line text to fill out empty space real text added later</h4> */}
        <div className='cmpny-social-btns'>
          <ul className='cmpny-socials-btns-list'>
            { socials.map((item, id) => (
                <li className="cmpny-socials-btns-item" key={id}>
                    <a
                      href={item.link}
                      target='blank'
                      className="cmpny-socials-btns-link"
                      // onClick={handleClick}
                    >
                        <img 
                          src={item.logo} 
                          className='cmpny-socials-btns-logo' 
                          alt={item.mediaName} 
                        />
                    </a>
                </li>
              ))}
          </ul>
        </div>
    </div>
  )
}

export default CompanySocials;