import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Navbar.css";
import logo from "../../assets/logo-00002.png";
import { FaAlignRight } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
// import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons";

function NavBar(props) { 
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const scenarioDropdownOptions = [
    {id: 0, name: "ice breaker", elementId: "iceBreaker"},
    {id: 1, name: "build your own scenario", elementId: "byos"},
    {id: 2, name: "jadu quiz", elementId: "jaduQuiz"},
    {id: 3, name: "IntelliSearch", elementId: "search"},
  ]
  
  const handleClick = () => setClick(!click);
  const [click, setClick] = useState(false);

  return ( 
    <>
      <nav className="navbar">
      <div className="navbar-shadow">
        <div className="nav-container">
          <NavLink  className="nav-logo">
          <span>
            <img src={logo} alt="Company Logo"  className="cmpny-logo"/>
          </span>
            {/* <i className="fas fa-code"></i> */}
            <span className="icon">
              {/* <CodeIcon /> */}
            </span>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}> 


            <li className="nav-item">
              <NavLink
                // exact
                to="/Simulation"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
               Scenario
              </NavLink>
              <div className="dropdown-menu-div">
                <ul className="dropdown-actions-ul">
                  {scenarioDropdownOptions.map((item,id) => (
                    <li 
                      className="scroll-to-list"
                      key={id}
                      onClick={() => handleClickScroll(item.elementId)}
                    >{item.name}</li>
                  ))}
                </ul>
              </div>
            </li>

            {/* <li className="nav-item">
              <NavLink
                // exact
                to="/analytics"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Profile
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                // exact
                to="/pricing"
                // activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Pricing
              </NavLink>
            </li> */}
            
        
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
              <IoCloseSharp />
              </span>
            ) : (
              <span className="icon">
              <FaAlignRight />
              </span>
            )}
          </div>
        </div>
        </div>

      </nav>
    </>
  );
}

export default NavBar;