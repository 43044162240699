import React, { useState } from "react";
import "../../../../../styles/IceBreaker/tinmeeting.css";
// import axiosInstance from "../../../../../interceptors/axios";
// import TinImageBox from "./TinBoxContent/TinImageBox";
import TinSummary from "./TinBoxContent/TinSummaryBox";
// import TinCheckAccros from "./TinBoxContent/TinCheckBox";
import TinDropDown from "./TinBoxContent/TinDropDown";

function TinMeeting() {
    const [ isVisible, setIsVisible ] = useState(false)
    const [ content, setContent ] = useState()

  return (
    <>
     <h2 className="icebreaker_title_mainheader">
        Ice Breaker... <span>TIN </span>It first!
      </h2>
      <div className="icebreaker_content" id="iceBreaker">
        <div className="icebreaker_box">
          <TinDropDown 
            setIsVisible={setIsVisible} 
            setContent={setContent}
            />

          {/* TIN Meeting ANSWER BOX CODE IS HERE */}
          {isVisible &&
            <div className="tin-three-container">
              {/* <TinImageBox /> */}
              <TinSummary content={content.with_who} />
              {/* <TinCheckAccros /> */}
            </div>}
          
        </div>



      </div>
    </>
  );
}

export default TinMeeting;