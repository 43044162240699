import React from "react";
import "../../../styles/pricingTable.css";

function PricingTablle() {
  return (
    <div className="revenue-streams-container">
      <h2 className="pricing-title">Revenue Streams</h2>

      <table className="stream-table">
        <thead>
          <tr>
            <th>Service</th>
            <th>Amount</th>
            <th>Duration</th>
            <th>Access</th>
          </tr>
        </thead>
        <tbody>
          <tr className="stream">
            <td>
              Subscription & Personalized Analytics and Simulation Stream
            </td>
            <td>99</td>
            <td>Per month</td>
            <td>Unlimited</td>
          </tr>

          <tr className="stream">
            <td>Coach Connect - soft skills/hard skills</td>
            <td>160</td>
            <td>per interaction</td>
            <td>limited to 15 min</td>
          </tr>

          <tr className="stream">
            <td>ice breaker</td>
            <td>99</td>
            <td>per interaction</td>
            <td></td>
          </tr>

          <tr className="stream">
            <td>Resume maker</td>
            <td>1000</td>
            <td>per resume</td>
            <td></td>
          </tr>

          <tr className="stream">
            <td>Industry Readiness Trajectory for campus placements</td>
            <td>1200</td>
            <td></td>
            <td></td>
          </tr>

          <tr className="stream">
            <td>Career Mobility Services - Job Connect</td>
            <td></td>
            <td>Free</td>
            <td></td>
          </tr>

          <tr className="stream">
            <td>
              Attempt randomly displayed scenarios and assess how do you come
              across as
            </td>
            <td></td>
            <td>Free</td>
            <td></td>
          </tr>

          <tr className="stream">
            <td>
              Choose your own scenario and see on a real-time basis what to say
            </td>
            <td></td>
            <td>Free</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PricingTablle;
