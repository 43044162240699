import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../../styles/footer/footerLinks.css';

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function FooterLinks() {
  const allLinks = [
    [
      { id: 1, linkName: 'Contact Us', link: '/contactus' },
      { id: 2, linkName: 'Cancellation and Refund Policy', link: '/refundpolicy' },
      { id: 3, linkName: 'Terms and Conditions', link: '/termsandconditions' },
      { id: 4, linkName: 'Privacy Policy', link: '/privacypolicy' },
    ],
    // Add more sections as needed
  ];

  return (
    <div className='footerlinks-container'>
      {/* Include ScrollToTopOnMount to maintain scroll position */}
      <ScrollToTopOnMount />

      {allLinks.map((section, id) => (
        <div className='footerlinks' key={id}>
          {section.map((item) => (
            <li className='footerlinks-link' key={item.id}>
              <Link to={item.link}>
                <h5 className='footerlinks-link-name'>{item.linkName}</h5>
              </Link>
            </li>
          ))}
        </div>
      ))}
    </div>
  );
}

export default FooterLinks;
