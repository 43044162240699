import React from 'react';
import '../../../../../../styles/IceBreaker/tinmeeting.css';

function TinDiscussSummary({ content }) {
  // const [showFullContent, setShowFullContent] = useState(false);

  const powerWords = content.power_word_used;
  const negativeWords = content.negative_word_used;

  // const toggleContent = () => {
  //   setShowFullContent(!showFullContent);
  // };

  // const truncatedContent = typeof content === 'string' ? content.slice(0, 100) : '';

  return (
    <div className={`tin-summery-box `}>
      <h1 className='tinbox-title'>Summary</h1>
      {/* <div className={`content-container ${showFullContent ? 'overflow-visible' : ''}`}>
        <p>{showFullContent ? content : truncatedContent}</p>
      </div> */}
      <div className='power-words-div'>
        <h1 className='power-word-title'>Power Words:</h1>
        <div className='power-words'>
          {powerWords.map((item, id) => (
            <p key={id} className='power-word'>
              {item}
              {(id === (powerWords.length - 1))? "" : ","}
            </p>
          ))}
        </div>
      </div>
      <div className='negative-words-div'>
        <h1 className='negative-words-title'>Negative Words:</h1>
        <div className='negative-words'>
          {negativeWords.map((item, id) => (
            <p key={id} className='negative-word'>
              {item}
              {(id === (negativeWords.length - 1))? "" : ","}
            </p>
          ))}
        </div>
      </div>
      {/* {!showFullContent && (
          <span onClick={toggleContent} className="read-more-button">
            Read More
          </span>
      )} */}
      </div>
  );
}

export default TinDiscussSummary;
