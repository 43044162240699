import React from 'react';
import logo from '../../assets/logo-00002.png';
import axiosInstance from '../../interceptors/axios';
import "../../styles/login.css";
// import { AiFillCloseCircle } from 'react-icons/ai';

function Popup() {

    const handleResend = () => {
        axiosInstance
          .post('accounts/api/send-confirmation-email/')
          .then(response => {
            console.log('Response:', response);
            // You can do further processing with the response here if needed
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle errors if the request fails
          });
      };
      

    return (
        <div className="content">
        <img className="logo" src={logo} alt="logo" />
        <div className="login-box">
        <h3 
        style={{color: "#fff",
        textAlign : "center",
        padding: "2rem"
        }}
        >
        A confirmation email has been sent to your registered email id. Please confirm your email id. If you have not received the email, please click Resend
        </h3>

        <button
  style={{
    width: "12rem",
    height: "51px",
    backgroundColor: "#371BC6",
    borderRadius: "26.50px",
    cursor: "pointer",
    color: "#FEA01A",
    fontSize: "1.2rem",
    fontWeight: "600",
  }}
  onClick={handleResend}
>
  Resend
</button>

        </div>
        </div>
    )
} 

export default Popup;

