import React, { useState, useEffect } from "react";
import "../../../../styles/Simulation/simulationQuiz.css";
import axiosInstance from "../../../../interceptors/axios";

function SimulationQuiz() {
  const [quizData, setQuizData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    axiosInstance.get('quiz/quiz_list/')
      .then(response => {
        console.log('quiz:', response.data);
        // Find the quiz item with the highest id
        const highestIdQuiz = response.data.reduce((prev, current) => {
          return (prev.id > current.id) ? prev : current;
        });

        setQuizData(highestIdQuiz);
      })
      .catch(error => {
        console.error("Error fetching quiz data:", error);
      });
  }, []);
 
  const handleOptionClick = (option) => {
    // Deselect the previously selected option, if any
    setSelectedOption(option === selectedOption ? null : option);
    setFeedback(null);

    if (option !== selectedOption) {
      // Select the new option and send the answer
      sendUserAnswer(quizData.id, option);
    }
  };

  const sendUserAnswer = (quizItemId, selectedOption) => {
    axiosInstance.put(`/quiz/api/quiz_results/${quizItemId}`, {
      selectedOption // Pass the selected option to the API
    })
    .then(response => {
      // Handle the response from the API
      const feedback = response.data;
      setFeedback(feedback);
      console.log('Answer submitted successfully:', feedback);
    })
    .catch(error => {
      console.error("Error submitting answer:", error);
    });
  };

  return (
    <>
      <div className="simulation_scnd_title" id="jaduQuiz">
        <h2>Master the art of influencing people around you</h2>
        <h3 className="simulation_smal_title">JADU Quiz for Today</h3>
      </div>

      <div className="simulation_content">
        <div className="simulation_box">
          {quizData && (
            <div className="simulation-quiz">
              <h3 className="simulation_second_box_title">{quizData.item_name}</h3>
              
                {["option1", "option2", "option3"].map((option, index) => (
                  <div className="option" key={index} onClick={() => handleOptionClick(option)}>
                    <div className="option-name">{quizData[option]}</div>
                    <label className="circle-checkbox">
                    <input
                      className="circle-inside-checkbox"
                      type="radio"
                      checked={option === selectedOption}
                      readOnly
                    />
                    </label>
                  </div>
                ))}
                {/* Add more options as needed */}
              

              <div className="simulation_multi">
                <h1 className="simulation_multi_ans">Correct Response-</h1>
                {selectedOption && feedback && (
                  <span className="simulation_datacome">{feedback["feedback" + selectedOption.charAt(selectedOption.length - 1)]}</span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SimulationQuiz;
