import React from "react";
import "../../../styles/AnalyticsScreen.css";
import "../../../styles/ImpactIndexcards.css";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const PersonalImpactindex = () => {
  // Retrieve the stored data from localStorage
  const storedPowerWords = localStorage.getItem("PowerWords");
  const storedWeekWords = localStorage.getItem("WeekWords");

  // Parse the retrieved data (assuming it was stored as a JSON string)
  const parsedPowerWords = storedPowerWords ? JSON.parse(storedPowerWords) : null;
  const parsedWeekWords = storedWeekWords ? JSON.parse(storedWeekWords) : null;

  return (
    <>
    
      <div className="wrapper">
        <div className="sean_analysis_boxes">
        <h2 className="card-heading">Words you use frequently</h2>
          <div className="sean_analysis_inside">
            {/* Display the retrieved data without quotes */}
            <h3>{parsedPowerWords}</h3>
          </div>
        </div>
        <h2 className="card-heading">Poor Word/Phrase Selection</h2>
        <div className="sean_analysis_inside">
          {/* Display the retrieved data without quotes */}
          <h3>{parsedWeekWords}</h3>
        </div>
      </div>
    </>
  );
};

export default PersonalImpactindex;
