import React, { useState } from "react";
import "../../styles/sentiCloud.css";
import { useNavigate } from "react-router-dom";
import SetniBarChart from "./setniBarChart/setniBarChart";

function SentiCloudTryJadu() {
    const navigate = useNavigate();
    // const [isExpanded, setIsExpanded] = useState(false);
    const [isRecommendExp, setIsRecommendExp] = useState(false);
    const Dataflow = JSON.parse(localStorage.getItem("sentiCloudData") || "{}");
    const SeanAnalysisData = Dataflow?.data?.data || {};
    
    // Constants for indices
    const subCompetencyIndex = 0;
    // const powerWordsIndex = 0;
    // const negativeWordsIndex = 0;
  
    // Define SeanRecommendData here to make it accessible throughout the function
    const seanRecmd = localStorage.getItem("seanRecomdData1");
    const SeanRecommendData = JSON.parse(seanRecmd);
  
// Fisher-Yates shuffle algorithm to shuffle an array
// Function to shuffle an array
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// Function to get a random word from an array and remove it
function getRandomAndRemove(array) {
    if (array.length === 0) {
        return null;
    }
    shuffleArray(array);
    return array.shift()?.word;
}

// Usage example
const competency = SeanRecommendData.competencys[subCompetencyIndex];
const subCompentency = competency?.sub_compentency || [];

// Shuffle the top-level array
shuffleArray(subCompentency);

// Initialize arrays to hold positive and negative words
const powerWords = [];
const negativeWords = [];

// Loop through the shuffled subCompentency to collect words
for (const item of subCompentency) {
    if (item.power_words) {
        powerWords.push(...item.power_words);
    }
    if (item.negative_words) {
        negativeWords.push(...item.negative_words);
    }
}

// Shuffle the arrays of positive and negative words
shuffleArray(powerWords);
shuffleArray(negativeWords);

// Get random words from the shuffled arrays
const powerWords1 = getRandomAndRemove(powerWords);
const powerWords2 = getRandomAndRemove(powerWords);
const powerWords3 = getRandomAndRemove(powerWords);

const negativeWords1 = getRandomAndRemove(negativeWords);
const negativeWords2 = getRandomAndRemove(negativeWords);
const negativeWords3 = getRandomAndRemove(negativeWords);

// console.log("Random Power Word 1:", powerWords1);
// console.log("Random Power Word 2:", powerWords2);
// console.log("Random Power Word 3:", powerWords3);
// console.log("Random Negative Word 1:", negativeWords1);
// console.log("Random Negative Word 2:", negativeWords2);
// console.log("Random Negative Word 3:", negativeWords3);



  
  
  
    // const toggleAnalysis = () => {
    //   setIsExpanded(!isExpanded);
    // };
  
    const recommendtoggleAnalysis = () => {
      setIsRecommendExp(!isRecommendExp);
    };
  
    const handleAnotherJadu = () => {
      navigate("/Simulation");
    };  

  return (
    <div className="sentiCloud-main-div">

      <div className="sentiCloud_title">
        <h2 className="sentiCloud_title_welcomer">Thank You!</h2>
        <h5>WE HAVE RECEIVED YOUR RESPONSE</h5>
        <h3 className="sentiCloud_linecolor">
          HOLD-ON
          <span className="sentiCloud_linecolor_head">
            {" "}
            FOR YOUR SCORE RESULT
          </span>
        </h3>
      </div>
      <div className="sentiCloud_header">
        <h3>SENTICLOUD ANALYSIS FOR YOUR RESPONSE</h3>
      </div>

      {/* Setni bar chart here start */}
      <div className="senti-barchart">
        <SetniBarChart />
      </div>

      {/* Sean Analysis code start here */}
      <div className="sean_boxes">
        <div className="sean_analysis">
          <div className="sean_analysis_boxheader">
            <h3 className="sean_analysis_boxtitle">SEAN Analysis</h3>
            {/* <button className="sean_toggle-btn" onClick={toggleAnalysis}>
              {isExpanded ? "-" : "+"}
            </button> */}
          </div>

          <div className="sean_analysis_boxes">
            <div className="sean_analysis_inside">
              <h3>YOU ARE COMING ACROSS AS</h3>
              <h3>{SeanAnalysisData.coming_across_as}</h3>
            </div>

            {/* {isExpanded && (
              <>
                <div className="sean_analysis_inside">
                  <h3>POWER WORDS YOU USED</h3>
                  <h3>{SeanAnalysisData.positive_traits}</h3>
                </div>

                <div className="sean_analysis_inside">
                  <h3>WEAK WORDS, YOU SHOULD TRY REPLACING</h3>
                  <h3>{SeanAnalysisData.negative_traits}</h3>
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>

      {/* Sean Recommend Part starts here */}
      <div className="sean_boxes">
        <div className="sean_analysis">
          <div className="sean_analysis_boxheader">
            <h3 className="sean_recommends_boxtitle">SEAN Recommends</h3>
            <button
              className="sean_toggle-btn"
              onClick={recommendtoggleAnalysis}
            >
              {isRecommendExp ? "-" : "+"}
            </button>
          </div>

          <div className="sean_analysis_boxes">
            <div className="sean_recommends_inside">
              <h3>YOU ARE COMING ACROSS AS</h3>
              <h3>{SeanAnalysisData.coming_across_as}</h3>
            </div>
            {isRecommendExp && (
              <>
                <div className="sean_recommends_inside">
                  <h3>POWER WORDS YOU SHOULD USED</h3>
                  <h3>
                    {powerWords1}, {powerWords2}, {powerWords3}
                  </h3>
                </div>
                <div className="sean_recommends_inside">
                  <h3>WEAK WORDS, YOU SHOULD TRY REPLACING</h3>
                  <h3>
                    {negativeWords1}, {negativeWords2}, {negativeWords3}
                  </h3>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="senti_btn_box">
        <button className="senti_btn" onClick={handleAnotherJadu}>
          TRY ANOTHER JADU
        </button>
      </div>
    </div>
  );
}

export default SentiCloudTryJadu;
