export default function serviceworker() {
    // Define the service worker URL
    let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  
    // Check if the browser supports service workers
    if ('serviceWorker' in navigator) {
      // Register the service worker when the window has loaded
      window.addEventListener('load', function () {
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            console.log('Service Worker registration successful with scope:', registration.scope);
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      });
    } else {
      console.log('Service worker is not supported');
    }
  }
  