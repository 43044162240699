import React, { useState, useEffect } from 'react';
import '../../../styles/AnalyticsScreen.css';
import ReactLoading from "react-loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart() {
    const [formattedData, setFormattedData] = useState([]);
    const [chartSeries, setChartSeries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: true,
            // text: 'Chart.js Bar Chart',
            color: 'white', // Title text color
          },
        },
      scales: {
        x: {
            grid: {
                color: 'rgba(255, 255, 255, 0.1)', // X-axis grid color
            },
            ticks: {
                color: 'white', // X-axis label color
            },
        },
        y: {
            grid: {
                color: 'rgba(255, 255, 255, 0.1)', // Y-axis grid color
            },
            ticks: {
                color: 'white', // Y-axis label color
            },
        },
    },
};

    const labels = formattedData.map((item) => item.category)

    const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: formattedData.map((item) => item.values[0]),
            backgroundColor: 'rgba(255, 25, 62, 0.9)',
          },
          {
            label: 'Dataset 2',
            data: formattedData.map((item) => item.values[1]),
            backgroundColor: 'rgba(53, 62, 135, 0.9)',
          },
          {
            label: 'Dataset 3',
            data: formattedData.map((item) => item.values[2]),
            backgroundColor: 'rgba(53, 162, 135, 0.9)',
          },
        ],
    };

    useEffect(() => {
        const fetchDataFromLocalStorage = async () => {
            try {
                const storedDataKey = localStorage.getItem('SpiderData');
                if (storedDataKey) {
                    const { competency_score } = JSON.parse(storedDataKey);
                    processData(competency_score);
                } else {
                    console.log(`No data found in localStorage for SpiderData.`);
                    setFormattedData([]); // Clear data if no data is found
                    setChartSeries([]);
                }
            } catch (error) {
                console.error(`Error fetching and processing data:`, error);
            } finally {
                setIsLoading(false);
            }
        };
    
        const processData = (competency_score) => {
            if (competency_score) {
                const categories = Object.keys(competency_score);
    
                if (categories.length > 0) {
                    const newFormattedData = categories.map((category) => {
                        const value = competency_score[category];
    
                        const typeCheck = () => {
                            if (typeof value === 'number') {
                                const formattedValue = value;
                                const newArr = [formattedValue, 0, 0];
                                return newArr;
                            } else if (Array.isArray(value)) {
                                return value;
                            }
                            return [0, 0, 0];
                        };
    
                        return {
                            category: category,
                            values: typeCheck(),
                        };
                    });
    
                    setFormattedData([newFormattedData[0], ...newFormattedData.slice(1)]);
                    const seriesData = {
                        name: 'Score',
                        data: newFormattedData.map((data) => data.values[0]),
                    };
                    setChartSeries([seriesData]);
                } else {
                    console.log('No valid categories found for the chart.');
                    setFormattedData([]); // Clear data if no valid categories are found
                    setChartSeries([]);
                }
            } else {
                console.log(`Invalid data format.`);
                setFormattedData([]); // Clear data if the data format is invalid
                setChartSeries([]);
            }
        };
    
        fetchDataFromLocalStorage(); // Fetch data immediately
    
        // Set up interval for background data refresh every 0.2 second
        const intervalId = setInterval(fetchDataFromLocalStorage, 200); // 0.2 seconds
    
        // Clear interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Run this effect only once on component mount
    

    return (
        <>
            {isLoading && (
        <div className="loading-overlay">
        <ReactLoading type="spin" color="#fff" height={50} width={50} />
        </div>
      )}
            <div className="chart-container">
                {chartSeries.length > 0 ? (
                    <Bar 
                    options={options} 
                    data={data} 
                    className='chart'/>
                ) : (
                    <p>No valid data available for the Bar Chart.</p>
                )}
            </div>

            <div className="table-data">
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formattedData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.category}</td>
                                <td>
                                    {data.values[0]}
                                    {/** do not put !== in conditions bellow, it is showing undefined on webpage */}
                                    {(data.values[1] < 0 || data.values[1] > 0)? "," + data.values[1] : ""}
                                    {(data.values[2] < 0 || data.values[2] > 0)? "," + data.values[2] : ""}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default BarChart;
