import React, { useState } from "react";
import Swal from "sweetalert2";
import { FaEnvelope } from "react-icons/fa";
import axios from "axios";

function RestPassEmailSend() {
  const [userDetails, setUserDetails] = useState({
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  //Call API for RESET PASSWORD EMAIL SEND
  const handleResetPassword = () => {
    axios
      .post(`https://aicansellapp.com/accounts/forgot_password/`, userDetails)
      .then((res) => {
        // Assuming res.data has the email details
        const showEmail = res;
        console.log(showEmail);


        if (showEmail) {
          // Email received
          Swal.fire({
            icon: "success", // Changed "Done" to "success" assuming it's a success message
            title: "Email Sent",
            text: `Mail Has been Sent ! Please Check Your Email`,
          });
        } else {
          // Handle scenarios where the email is not received or not available in the response
          Swal.fire({
            icon: "error",
            title: "Email Not Found",
            text: "The provided email is not registered.",
          });
        }
      })
      .catch((error) => {
        // Handle API call errors
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending the password reset email.",
        });
      });
  };

  return (
    <div className="content">
      <div className="login-box">
        <h2 
        style={{
            color: "#fff",
            marginBottom : "2rem"
        }}
        >Forgot Password</h2>

        <div
          className={`form-row input-container ${
            userDetails.email && "input-filled"
          }`}
        >
          <p className={`form--label input-label`}>
            <FaEnvelope className="input-icon" /> Email address
          </p>
          <input
            className="input"
            type="email"
            name="email"
            value={userDetails.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="login-button" onClick={handleResetPassword}>
          <span className="button-text">RESET PASSWORD</span>
        </div>
      </div>
    </div>
  );
}

export default RestPassEmailSend;
