import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
// import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/Signup';
import SendMail from './components/SucessPopup/SuccessPop';
import Simulation from './components/aiSimulation/Simulation';
import SentiCloudTryJadu from './components/SentiCloudScrn19/sentiCloud';
import './styles/app.css';
import background from './assets/AiCanSell-background.png';
import RestPassEmailSend from './components/RestPassword/RestCnfrmMail';
import Newpassword from './components/RestPassword/NewPassWord';
import NavBar from './components/NavBar/NavBar';
import AnalyticsScreen from "./components/analyticsScreen/analyticsScreen";
import Footer from "./components/Footer/Footer";
// import BottomBar from "./components/Footer/BottomBar";
import CancellationRefundPolicy from './components/Footer/FooterPages/Cancellation';
import TermsAndConditions from './components/Footer/FooterPages/TermsConditions';
import PrivacyPolicy from './components/Footer/FooterPages/PrivacyPolicy ';
import ContactUs from './components/Footer/FooterPages/ContactUs';
import PricingTablle from './components/Pricing/PricingTable/PricingTable';

// Create a separate component for conditional Navbar rendering
function ConditionalNavbar() {

  const location = useLocation();

  // List of routes where you want to hide the Navbar
  const routesWithoutNavbar = ["/", "/Signup", "/mailsend", "/Restmailsend", "/admin","/AdminAnalytics", "/accounts/reset_password/:token?"];

  // Check if the current route is in the list of routes without Navbar
  const isNavbarHidden = routesWithoutNavbar.includes(location.pathname);

  return !isNavbarHidden && <NavBar />;
}

function ConditionalFooter() {
  const location = useLocation();

  // List of routes where you want to hide the Footer
  const routesWithoutFooter =  ["/", "/Signup", "/mailsend", "/Restmailsend", "/admin","/AdminAnalytics", "/accounts/reset_password/:token?"];

  // Check if the current route is in the list of routes without Footer
  const isFooterHidden = routesWithoutFooter.includes(location.pathname);

  return !isFooterHidden && <Footer />;
}

// function ConditionalBottomBar() {
//   const location = useLocation();

//   // List of routes where you want to hide the BottomBar
//   const routesWithoutBottomBar =  ["/", "/Signup", "/mailsend", "/Restmailsend", "/admin","/AdminAnalytics", "/accounts/reset_password/:token?"];

//   // Check if the current route is in the list of routes without BottomBar
//   const isBottomBarHidden = routesWithoutBottomBar.includes(location.pathname);

//   return !isBottomBarHidden && <BottomBar />;
// }

function App() {
  
  return (
    <Router>
      <div className="App">
        <img src={background} alt="background" className="background" />
        <ConditionalNavbar />
        <Routes>
          {/* <Route exact path="/" element={<Home />} /> */}
          <Route path="/" element={<Login />}/>
          <Route path="/Signup" element={<SignUp />}/>
          <Route path="/Restmailsend" element={<RestPassEmailSend />} />
          <Route path="/accounts/reset_password/:token?" element={<Newpassword />} />

          {/* {After Login Send Cnfrm Mail} */}
          <Route path="/mailsend" element={<SendMail />}/> 
          <Route path="/Simulation" element={<Simulation />}/>
          <Route path="/SentiCloudTryJadu" element={<SentiCloudTryJadu />}/>
          <Route path="/analytics" element={<AnalyticsScreen />}/>
          <Route path="/refundpolicy" element={<CancellationRefundPolicy />}/>
          <Route path="/termsandconditions" element={<TermsAndConditions />}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}/>
          <Route path="/contactus" element={<ContactUs />}/>
          <Route path="/pricing" element={<PricingTablle />}/>
        </Routes>

        <div className="footer-container-div">
          <ConditionalFooter />
          {/* <ConditionalBottomBar /> */}
        </div>
      </div>
    </Router>
  );
}

export default App;
