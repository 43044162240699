import React, { useState, useEffect } from "react";
import "../../../styles/Simulation/simulation.css";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { FaSearch } from 'react-icons/fa';
import SimulationQuiz from "./SimulationQuiz/SimulationQuiz";
import axiosInstance from "../../../interceptors/axios";
import ReactLoading from "react-loading";
import SpeechToText from "./SpeechToText/SpeechToText";

function Mail() {
  const [responseData, setResponseData] = useState([]);
  const [textInputValue, setTextInputValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [isChangingThumbnail, setIsChangingThumbnail] = useState(false); // State to control thumbnail change delay
  const [isContentVisible, setIsContentVisible] = useState(false);
  const navigate = useNavigate();
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [shuffledCategories, setShuffledCategories] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [transcript, setTranscript] = useState("");


  
  const handleTranscriptChange = (newTranscript) => {
    setTranscript(newTranscript);
  };

  useEffect(() => {
    const lastSelectedCategory = localStorage.getItem('lastSelectedCategory');
    if (lastSelectedCategory) {
      setSelectedCategory(lastSelectedCategory);
    }

    axiosInstance
      .get('sean/itemli/')
      .then(response => {
        const data = response.data.filter(item => item.item_type === "Email");
        setResponseData(data);
        console.log(data)
        const uniqueCategories = [...new Set(data.map(item => item.category))];
        setCategories(uniqueCategories);
        console.log(uniqueCategories)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const handleInputChange = (event) => {
    setTextInputValue(event.target.value);
  };

  const handleSearch = () => {
    console.log('Searching...'); // Replace this with your search logic
  };

  const handleButtonClick = (category) => {
    setSelectedCategory(category);
    setIsContentVisible(true);

    // Delay changing the thumbnail by 2 seconds
    setIsChangingThumbnail(true);
    setTimeout(() => {
      // Find the corresponding thumbnail for the selected category
      const selectedCategoryItem = responseData.find(item => item.category === category);
      if (selectedCategoryItem) {
        setSelectedThumbnail(selectedCategoryItem.thumbnail);

         // Set the local storage values here
        localStorage.setItem('lastSelectedCategory', category);
        localStorage.setItem('emailbtnid', selectedCategoryItem.id)
      }
      setIsChangingThumbnail(false);
      // setLoading(false);
    }, 1000);
  };

  // console.log(localStorage.getItem('emailbtnid'))
  const filteredItems = responseData.filter(item => item.category === selectedCategory);
  const EmailIDsend = localStorage.getItem('emailbtnid')

  const handleQuestionSubmit = () => {
    // Check if the textarea is empty
    if (textInputValue.trim() === "" && transcript.trim() === "") {
    // Show an error message using SweetAlert
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Please type anything in the textarea',
    });
   } else {
    setLoading(true); // Set loading to true when the user clicks the submit button
      // Define the data you want to send to the API
      const data = {
        item_answer: `${transcript} ${textInputValue}`,
        // ... other data you want to send
      };
  
      // Make the PUT API request using Axios
      axiosInstance
        .put(`sean/api/item_results/${EmailIDsend}`, data)
        .then((response) => {
          // Handle a successful response from the PUT API, if needed
          console.log('API Response:', response.data.data);
          const StoreData = response;
          localStorage.setItem("sentiCloudData", JSON.stringify(StoreData));
  
          // After the PUT request, make a GET request without a request body
          axiosInstance
            .get(`sean/api/item_rec/${EmailIDsend}`)
            .then((getResponse) => {
              // Handle a successful response from the GET API
              console.log('GET API Response:', getResponse.data.competencys);
              const SeanRecomdData = getResponse.data;
              localStorage.setItem("seanRecomdData1", JSON.stringify(SeanRecomdData));
  
              // Perform any additional logic with the GET response data
  
              // Now, navigate to another page or do further processing
              navigate("/SentiCloudTryJadu");
            })
            .catch((getErr) => {
              // Handle errors from the GET request
              console.error('GET API Error:', getErr);
            });
        })
        .catch((error) => {
          // Handle errors from the PUT request, e.g., show an error message to the user
          console.error('API Error:', error);
        })
        .finally(() => {
          setLoading(false); // Set loading back to false when the request is complete
        });
      };
    };

 // Button shuffleArray

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  
  useEffect(() => {
    // Shuffle the categories and select three of them
    const shuffledCategoriesResult = shuffleArray(categories).slice(0, 3);
    setShuffledCategories(shuffledCategoriesResult);
  }, [categories]); // Include 'categories' in the dependency array to address the linting warning
  
  

  return (
    <div className="email-main-div">
      {loading && (
        <div className="loading-overlay">
          <ReactLoading type="spin" color="#fff" height={50} width={50} />
        </div>
      )}
      
    <h3 className="simulation_mean_header">Please click on the below competencies to see the scenario and respond to it</h3>
    <div className="simulation_btn">
  {shuffledCategories.map((category, index) => (
    <button
      key={index}
      className={index === activeButtonIndex ? "active-button" : "inactive-button"}
      onClick={() => {
        handleButtonClick(category);
        setActiveButtonIndex(index);
      }}
    >
      {category}
    </button>
  ))}
</div>

{isContentVisible && (
      <div className="simulation_content">
        <div className="simulation_box">
          <h3 className="simulation_box_title">How would you respond to this situation. (Max 30 words)</h3>

          {filteredItems.map((item, index) => (
            <div key={index} className="simulation_item">
              <div className="simulation_content_pic">
         
    <img className="simulation_thumb" src={isChangingThumbnail ? selectedThumbnail : item.thumbnail} alt="thumbnail" />
</div>

<div className="simulation_title_left">
    <h4 className="simulation_heading_left">{item.item_name}</h4>
    <SpeechToText
                    onTranscriptChange={handleTranscriptChange}
                    onChange={handleInputChange}
                  />

            <button 
            className="simulation_sumbt_btn"
            onClick={handleQuestionSubmit}
            >
              Submit
            </button>
</div>
            </div>
          ))}

        </div>
      </div>
      )}

      {/* Second Title Section starts here */}
      <SimulationQuiz />

      {/* Third section Starts here */}
      <div className="simulation_third_selection">
        <div className="simulation_third_title">
          <h2 className="simulation_third_header">AI IntelliSearch - Learn in 3 min</h2>
          <h3 className="simulation_third_head">Personal Branding, Sales Intelligence, and lots more</h3>
        </div>
      </div>

      <div className="search-box" id='search'>
        <input
          type="text"
          placeholder="Search..."
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <FaSearch className="search-icon" />
      </div>
    </div>
  );
}

export default Mail;
