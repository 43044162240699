import React, { useState } from "react";
import "../../../../../styles/IceBreaker/tinmeeting.css";
import TinDiscussSummary from "./TinDiscussionContent/TinDisSummarybox";
// import TinDiscussCheckAccros from "./TinDiscussionContent/TinDiscusAcrosBox";
// import TinDiscusPicBox from "./TinDiscussionContent/TinDiscusPicBox";
import TinDiscussDropDown from "./TinDiscussionContent/TinDiscusDropDown";

function TinDiscuss() {
  const [ isVisible, setIsVisible ] = useState(false)
  const [ content, setContent ] = useState()

    // const exampleContent =
    // "It looks like there might be a small typo in your code. The correct pseudo-class for styling the focus-visible state is :focus-visible (with a colon), not :focus-visable. Here's the corrected code:";


  return (
    <>
     <h2 className="icebreaker_title_mainheader">
        Build your own scenario. <span>TIN </span>It first!
      </h2>

      <div className="icebreaker_content" id="byos">
        <div className="icebreaker_box">
          <TinDiscussDropDown 
            setIsVisible={setIsVisible} 
            isVisible={isVisible}
            setContent={setContent}
          />

          {/* TIN Meeting ANSWER BOX CODE IS HERE */}
          {isVisible && 
          <div className="tin-three-container">
            <TinDiscussSummary content={content} />
            {/* <TinDiscusPicBox /> */}
            {/* <TinDiscussCheckAccros /> */}
          </div>
          }
        </div>
      </div>
      
    </>
  );
}

export default TinDiscuss;