import React, { useState, useEffect } from "react";
import bgAi from "../../assets/bg-ai.png";
import logo from "../../assets/logo-00002.png";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import "../../styles/login.css";
import axios from "axios";

function NewPassWord() {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [savedToken, setSavedToken] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split('/');

    // Find the index of 'reset_password' in the path
    const resetPasswordIndex = pathSegments.indexOf('reset_password');
    if (resetPasswordIndex !== -1) {
      // Extract the token from the path after 'reset_password'
      const tokenValue = pathSegments.slice(resetPasswordIndex + 1).join('/');
      setSavedToken(tokenValue);
      console.log(tokenValue);
    }
  }, [location.pathname]);

  console.log(savedToken);

  const handlePasswordToggle1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handlePasswordToggle2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "confirmPassword") {
      if (value === formData.password) {
        setPasswordMatch(true);
        setPasswordError("");
      } else {
        setPasswordMatch(false);
        setPasswordError("Passwords do not match.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // ... (password validation logic remains the same)

    if (!passwordMatch) {
      Swal.fire("Passwords do not match.", passwordError, "error");
      return;
    }

    try {
      const response = await axios.post(
        `https://aicansellapp.com/accounts/reset_password/${savedToken}`,
        formData
      );

      console.log(response)

      Swal.fire("Password changed!", "Your password has been updated.", "success");
      localStorage.setItem("sendToken", savedToken);
      navigate("/");
    } catch (error) {
      console.error("API Error:", error);

      if (error.response && error.response) {
        const errorMessage =
          error.response || "An error occurred while changing the password.";
        Swal.fire("Error", errorMessage, "error");
      } else {
        Swal.fire("Error", "An error occurred while changing the password.", "error");
      }
    }
  };

  return (
    <div>
      <div className="background-l">
        <img src={bgAi} alt="background" className="background-image" />
      </div>
      <div className="content">
        <img className="logo" src={logo} alt="logo" />

        <div className="login-box">
          <div className="login-header">
            <h2 className="header-text">RESET PASSWORD</h2>
          </div>
          <form onSubmit={handleSubmit} className="form">
            <div className={`form-row input-container`}>
              <p className={`form--label input-label`}>
                <FaLock className="input-icon" /> New Password
              </p>
              <input
                className="input"
                type={showPassword1 ? 'text' : 'password'}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <span onClick={handlePasswordToggle1} className="password-toggle">
                {showPassword1 ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className={`form-row input-container`}>
              <p className={`form--label input-label`}>
                <FaLock className="input-icon" /> Confirm Password
              </p>
              <input
                className="input"
                type={showPassword2 ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
              <span onClick={handlePasswordToggle2} className="password-toggle">
                {showPassword2 ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="login-button" onClick={handleSubmit}>
                            <span className="button-text">RESET</span>
                        </div>
          </form>

          <div className="signup-link">
            <span className="signup-text">Back To </span>
            <span className="signup-link-text" onClick={() => navigate("/")}>
              Sign In
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassWord;
