import React from 'react';
// import "../../styles/footer/bottomBar.css"

function BottomBar() {
  return (
    <div className='bottom-bar'>
        <p className='bottom-bar-tag'>© 2023 AiCanSell.</p>
        {/* add lang selector below */}
        {/* <div className='bottom-bar-lang-select'>language selector</div> */}
    </div>
  )
}

export default BottomBar;