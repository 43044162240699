import React from "react";
import "../styles/toggle.css";

const ToggleSwitch = ({ active, onClick }) => {
  const switchContainerStyle = active ? "switch-container activeRight" : "switch-container activeLeft";
  const simulationLabelStyle = active ? "label active" : "label";
  const mailLabelStyle = active ? "label" : "label active";

  return (
    <div className={switchContainerStyle}>
      <span className={simulationLabelStyle}>Mail</span>
      <label className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          checked={!active}
          onChange={onClick}
        />
        <span className="slider round"></span>
      </label>
      <span className={mailLabelStyle}>Simulation</span>
    </div>
  );
};

export default ToggleSwitch;
