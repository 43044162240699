import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../../../interceptors/axios";

function TinDropDown(props) {
  const setIsVisible = props.setIsVisible;
  const setContent = props.setContent

  const [inputValue, setInputValue] = useState("");
  const [dropdown1Value, setDropdown1Value] = useState(null);
  const [dropdown2Value, setDropdown2Value] = useState(null);
  const [showAdditionalInput1, setShowAdditionalInput1] = useState(false);
  const [showAdditionalInput2, setShowAdditionalInput2] = useState(false);
  // const inputData = {
  //   going_for: dropdown1Value,
  //   with_who: inputValue,
  //   help_on: dropdown2Value,
  // }

  const handleInputChange = (e) => {
    const inputValueWithSpaces = e.target.value.replace(/\s+/g, " ");
    const words = inputValueWithSpaces.split(" ");
    const truncatedWords = words.slice(0, 6);
    const truncatedValue = truncatedWords.join(" ");

    setInputValue(truncatedValue);
  };

  // useEffect(() => {
  //   sessionStorage.setItem("input data", inputData)
  // }, [inputData])

  // if (
  //   (dropdown1Value !== null && !showAdditionalInput1) ||
  //   (dropdown2Value !== null && !showAdditionalInput2) ||
  //   inputValue.trim() !== ""
  // ) {
  //   setIsVisible((prev) => !prev);
  // } 

  const handleSubmit = () => {
     // Check if the required values are present
  if (
    (dropdown1Value !== null && !showAdditionalInput1) ||
    (dropdown2Value !== null && !showAdditionalInput2) ||
    inputValue.trim() !== ""
  ) {
      const inputData = {
        going_for: dropdown1Value,
        with_who: inputValue,
        help_on: dropdown2Value,
      };

      axiosInstance
        .post("icebreaker/process/", inputData)
        .then(async (response) => {
          console.log("data sent", response.status)
          if (response.status === 201) {
            setIsVisible((prev) => !prev)
            setContent(response.data.data)
          }
        })
        .catch((error) => {
          console.log("error:" + error)
        })
      }
};

  // Check the selected option in the first dropdown
  useEffect(() => {
    if (dropdown1Value === "other") {
      setShowAdditionalInput1(true);
    } else {
      setShowAdditionalInput1(false);
    }
  }, [dropdown1Value]);

  // Check the selected option in the second dropdown
  useEffect(() => {
    if (dropdown2Value === "other") {
      setShowAdditionalInput2(true);
    } else {
      setShowAdditionalInput2(false);
    }
  }, [dropdown2Value]);

  

  return (
    <>
      <h2 className="icebreaker_title">
        Important Meeting? <span>TIN </span>It first!
      </h2>

      <div className="maindropdown-container">
        {/* First Dropdown */}
        <div className="dropdown-input-container">
        <div className="dropdown-container-tin">
          <label className="dropdown-labeltin">I Am Going For</label>
          <select
            id="dropdown1"
            className="dropdownselect-tin"
            value={dropdown1Value}
            onChange={(e) => setDropdown1Value(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
            <option value="other">Other</option>
          </select>
        </div>

        {/* Additional Input Section for Dropdown 1 */}
        {showAdditionalInput1 && (
          <div className="additional-input-container">
            {/* <label className="additional-input-label">
              Additional Input Section for Dropdown 1
            </label> */}
            <textarea
              type="text"
              id="additionalInputField1"
              className="additional-input"
              placeholder="Add Your Own Message"
              // Add necessary event handlers and state if needed
            />
          </div>
        )}
        </div>

        {/* Third Dropdown */}
      <div className="dropdown-container-tin">
        <label className="dropdown-labeltin">
          with
        </label>
        <textarea
          type="text"
          id="inputFieldMetting"
          className="input-tin"
          placeholder="Enter your text (7 words max)"
          maxLength="50"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>

        {/* Second Dropdown */}
        <div className="dropdown-input-container">
        <div className="dropdown-container-tin">
          <label className="dropdown-labeltin">Want Help On</label>
          <select
            id="dropdown2"
            className="dropdownselect-tin"
            value={dropdown2Value}
            onChange={(e) => setDropdown2Value(e.target.value)}
          >
            <option value="">Select an option</option>
            <option value="option4">Option 4</option>
            <option value="option5">Option 5</option>
            <option value="option6">Option 6</option>
            <option value="other">Other</option>
          </select>
        </div>

        {/* Additional Input Section for Dropdown 2 */}
        {showAdditionalInput2 && (
          <div className="additional-input-container">
            {/* <label className="additional-input-label">
              Additional Input Section for Dropdown 2
            </label> */}
            <textarea
              type="text"
              id="additionalInputField2"
              className="additional-input"
              placeholder="Add Your Own Message"
              // Add necessary event handlers and state if needed
            />
          </div>
        )}
      </div>
      </div>

      

      {/* Submit btn code here */}
      <div className="submitbtn-tin">
        <button className="tin-btn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}

export default TinDropDown;
