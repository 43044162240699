import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip,  ResponsiveContainer } from 'recharts';

function SentiBarchart() {
  // Retrieve the data from local storage
  const BarChartData = JSON.parse(localStorage.getItem("sentiCloudData") || "{}");

  // Extract the data
  const comingAcrossAsData = BarChartData?.data?.coming_across_as || "";

  // Function to convert the string data into an array of objects
  function convertData(data) {
    const dataItems = data.split(',').map(item => item.trim());

    const dataArray = dataItems.map(item => {
      const [name, value] = item.split(':');
      return {
        name: name.trim().replace(/'/g, ''),
        value: parseInt(value, 10)
      };
    });

    return dataArray;
  }

  const ShowBarChart = convertData(comingAcrossAsData);

  // Log the ShowBarChart data for debugging
  console.log(ShowBarChart);

  return (
    <div style={{ width: '75%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart data={ShowBarChart}>
          {/* BarChart components */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="value" fill="#C631F3" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default SentiBarchart;
