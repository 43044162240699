import React, { useState } from 'react';
import '../../../../../../styles/IceBreaker/tinmeeting.css';

function TinSummary({ content }) {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const truncatedContent = typeof content === 'string' ? content.slice(0, 100) : '';

  return (
    <div className={`tin-summery-box ${showFullContent ? 'expanded' : ''}`}>
      <h1 className='tinbox-title'>Summary</h1>
      <div className={`content-container ${showFullContent ? 'overflow-visible' : ''}`}>
        <p>{showFullContent ? content : truncatedContent}</p>
      </div>
      {!showFullContent && (
          <span onClick={toggleContent} className="read-more-button">
            Read More
          </span>
      )}
      </div>
  );
}

export default TinSummary;
