// SpeechToText.js
import React, { useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";

function SpeechToText({ onTranscriptChange }) {
  const { transcript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [combinedText, setCombinedText] = useState(''); // State to store combined text

  useEffect(() => {
    // Combine spoken and manually typed text
    const updatedText = isListening ? transcript : combinedText;
    setCombinedText(updatedText);
  }, [transcript, isListening, combinedText]);

  const toggleListening = () => {
    if (isListening) {
      SpeechRecognition.stopListening();
      setIsListening(false);
    } else {
      SpeechRecognition.startListening();
      setIsListening(true);
    }
  };

  // Update the combined text
  const handleInputChange = (e) => {
    setCombinedText(e.target.value);
  };

  // Pass the combined text to the parent component
  useEffect(() => {
    onTranscriptChange(combinedText);
  }, [combinedText, onTranscriptChange]);

  return (
    <div>
      <div>
        <textarea
          className="simulation_textInput"
          value={combinedText} // Display both spoken and typed text
          onChange={handleInputChange}
          disabled={isListening}
          rows={4}
          cols={50}
        />
      </div>
      <div>
        <span onClick={toggleListening}>
          {isListening ? <BsFillMicMuteFill style={{ width: '30px', color: "white" }} /> : <BsFillMicFill style={{ width: '30px', color: "white" }} />}
        </span>
      </div>
    </div>
  );
}

export default SpeechToText;
