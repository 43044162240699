import React, { useState } from "react";
import bgAi from "../assets/bg-ai.png";
import logo from "../assets/logo-00002.png";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../interceptors/axios";
import { FaEnvelope, FaLock, FaUserAlt } from "react-icons/fa";
import { FaFacebook, FaGoogle, FaLinkedin } from "react-icons/fa";
import "../styles/login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2';


function Signup() {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const handleRegistration = () => {
  if (userDetails.password !== userDetails.confirmPassword) {
    // Use Swal to display an error message
    Swal.fire({
      icon: 'error',
      title: 'Password Mismatch',
      text: 'The passwords do not match. Please make sure they match.',
    });
    return;
  }

  axiosInstance
    .post("accounts/register/", userDetails)
    .then(async (res) => {
      navigate("/");
      console.log(res);
      console.log(userDetails);
    })
    .catch((error) => {
      console.error("Registration failed:", error);
    });
};


  const handleSocialMediaClick = (url) => {
    window.open(url, "_blank"); // Opens the social media link in a new tab
    // If you want to use React Router for navigation:
    // history.push(url);
  };

  const handleLoginClick = () => {
    // Navigate to the login page
    navigate("/");
  };

  return (
    <div>
      <div className="background-l">
        <imgage
          src={bgAi}
          alt="background image"
          className="background-image"
        />
      </div>
      <div className="content">
        <img className="logo" src={logo} alt="logo" />

        <div className="login-box">
          <div className="login-header">
            <h2 className="header-text">SIGNUP/CREATE ACCOUNT</h2>
          </div>

          <form onSubmit={handleRegistration} className="form">
            <div className="form-row">
              <p className="form--label">
                <FaUserAlt className="input-icon" />
                First Name
              </p>
              <input
                className="input"
                type="text"
                name="first_name"
                value={userDetails.first_name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, first_name: e.target.value })
                }
                required
              />
            </div>
            <div className="form-row">
              <p className="form--label">
                <FaUserAlt className="input-icon" />
                Last Name
              </p>
              <input
                className="input"
                type="text"
                name="last_name"
                value={userDetails.last_name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, last_name: e.target.value })
                }
                required
              />
            </div>
            <div className="form-row">
              <p className="form--label">
                <FaEnvelope className="input-icon" />
                Email address
              </p>
              <input
                className="input"
                type="email"
                name="email"
                value={userDetails.email}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
                required
              />
            </div>
            <div className="form-row">
              <p className="form--label">
                <FaUserAlt className="input-icon" />
                Username
              </p>
              <input
                className="input"
                type="text"
                name="username"
                value={userDetails.username}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, username: e.target.value })
                }
                required
              />
            </div>
            <div className="form-row">
  <p className="form--label">
    <FaLock className="input-icon" />
    Password
  </p>
  <input
    className="input"
    type={showPassword ? 'text' : 'password'}
    name="password"
    value={userDetails.password}
    onChange={(e) =>
      setUserDetails({ ...userDetails, password: e.target.value })
    }
    required
  />
  <span
    className="password-toggle"
    onClick={() => setShowPassword(!showPassword)}
  >
    {showPassword ? <FaEyeSlash /> : <FaEye />}
  </span>
</div>

<div className="form-row">
  <p className="form--label">
    <FaLock className="input-icon" />
    Confirm Password
  </p>
  <input
    className="input"
    type={showConfirmPassword ? 'text' : 'password'}
    name="confirmPassword"
    value={userDetails.confirmPassword}
    onChange={(e) =>
      setUserDetails({ ...userDetails, confirmPassword: e.target.value })
    }
    required
  />
  <span
    className="password-toggle"
    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
  >
    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
  </span>
</div>

            <div className="form-row">
              {/* <label className="form--label"> */}
              <input
                style={{
                  marginLeft: "8rem",
                  /* Add other styles as needed */
                }}
                type="checkbox"
                checked={agreedToTerms}
                onChange={() => setAgreedToTerms(!agreedToTerms)}
              />

              {/* </label> */}
              <span
                style={{ color: "white", font: "Segoe UI", fontSize: "1rem" }}
              >
                {" "}
                I agree to the{" "}
              </span>
              <span
                style={{ color: "#FEA01A", font: "Segoe UI", fontSize: "1rem" }}
              >
                Terms & Conditions{" "}
              </span>
              <span
                style={{ color: "white", font: "Segoe UI", fontSize: "1rem" }}
              >
                {" "}
                and{" "}
              </span>
              <span
                style={{ color: "#FEA01A", font: "Segoe UI", fontSize: "1rem" }}
              >
                {" "}
                Privacy Policy{" "}
              </span>
            </div>

            {/* Your other form elements here */}

            <div className="login-button" onClick={handleRegistration}>
              <span className="button-text">Create Account</span>
            </div>
          </form>
          <span className="signup-bottom">Signup With</span>
          <div className="social-icons">
            <div
              className="social-icon"
              onClick={() => handleSocialMediaClick("YOUR_FACEBOOK_URL")}
            >
              <FaFacebook size={32} />
            </div>
            <div
              className="social-icon"
              onClick={() => handleSocialMediaClick("YOUR_GOOGLE_URL")}
            >
              <FaGoogle size={32} />
            </div>
            <div
              className="social-icon"
              onClick={() => handleSocialMediaClick("YOUR_LINKEDIN_URL")}
            >
              <FaLinkedin size={32} />
            </div>
          </div>

          <h2 style={{ color: "white", fontSize: "1rem" }}>
            Already have an account?
            <span
              style={{
                color: "#FEA01A",
                cursor: "pointer",
              }}
              onClick={handleLoginClick}
            >
              Login
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Signup;
