import React from 'react';
import '../../../styles/footer/footerPages/PrivacyPolicy.css';

function PrivacyPolicy () {

  return (
    <div className="privacypolicy-container">
      <div className="privacypolicy-policy">
        <h2 className="privacypolicy">Privacy Policy</h2>

        <p className="privacypolicy">
          This Privacy Policy describes how Pecai Technologies Pvt. Ltd. collects, uses, and protects the information you provide when you use our website/application.
        </p>

        <h3 className="privacypolicy">1. Information We Collect</h3>

        <p className="privacypolicy">
          <strong>a. Personal Information:</strong> We may collect personal information such as your name, email address, and contact details when you voluntarily submit it through our website/application.
        </p>

        <p className="privacypolicy">
          <strong>b. Non-Personal Information:</strong> We may also collect non-personal information, such as browser type, operating system, and IP address, to enhance your experience on our website/application.
        </p>

        <h3 className="privacypolicy">2. How We Use Your Information</h3>

        <p className="privacypolicy">
          <strong>a. We use the information we collect for the following purposes:</strong>
          <br />
          - To personalize your experience on our website/application.
          <br />
          - To improve our website/application based on your feedback.
          <br />
          - To send periodic emails with updates, promotions, and other information you may find relevant.
        </p>

        <p className="privacypolicy">
          <strong>b. We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as described in this Privacy Policy.
          </strong>
        </p>

        <h3 className="privacypolicy">3. How We Protect Your Information</h3>

        <p className="privacypolicy">
          <strong>a. We implement a variety of security measures to maintain the safety of your personal information.</strong>
        </p>

        <p className="privacypolicy">
          <strong>b. Your personal information is stored on secure servers and is only accessible by authorized personnel.</strong>
        </p>

        <h3 className="privacypolicy">4. Cookies</h3>

        <p className="privacypolicy">
          <strong>a. We use cookies to enhance your experience on our website/application.</strong>
        </p>

        <p className="privacypolicy">
          <strong>b. You can choose to disable or selectively turn off our cookies or third-party cookies in your browser settings.</strong>
        </p>

        <h3 className="privacypolicy">5. Third-Party Links</h3>

        <p className="privacypolicy">
          Occasionally, at our discretion, we may include third-party links on our website/application. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.
        </p>

        <h3 className="privacypolicy">6. Changes to Our Privacy Policy</h3>

        <p className="privacypolicy">
          If we decide to change our Privacy Policy, we will post those changes on this page, and update the modification date below.
        </p>

        <h3 className="privacypolicy">7. Contact Us</h3>

        <p className="privacypolicy">
          If you have any questions regarding this Privacy Policy, you may contact us at <a className= "privacypolicy" href="mailto:info@aicansell.com">info@aicansell.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
