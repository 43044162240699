import React from 'react';
import CompanySocials from './CompanySocials/CompanySocials';
import FooterLinks from './footerLinks/FooterLinks';
// import GetAppBtns from './GetAppBtns/GetApp';
import "../../styles/footer/footer.css";
import BottomBar from './BottomBar';

function Footer() {
  return (
    <div className='footer'>
      <BottomBar />
        <CompanySocials />
        <FooterLinks />
        {/* <GetAppBtns /> */}
    </div>
  )
}

export default Footer