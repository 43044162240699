import React,  { useState } from "react";
import "../../styles/AnalyticsScreen.css";
// import { useNavigate } from "react-router-dom";
import arrows from "../../assets/arrows 1.png";
import example from "../../assets/Scenarios_img.svg";
import SpiderChart from "./SpiderGraph/SpiderGraph";
// import BarChart from "./BarGraph/analyticsBarChart";
import axiosInstance from "../../interceptors/axios";
import PersonalImpactindex from "../PersonLimpact/toggleimpactindex/ImpactIndexcards";

function AnalyticsScreen() {
  // const navigate = useNavigate();
const [simulationData, setsimulationData] = useState("");

  // const handleAnotherJadu = () => {
  //   navigate("/impactdata");
  // };

  axiosInstance
  .get(`sean/itemhandle/`)
  
  .then(response =>{
    const DataHandle = response.data.user_details;
// Extract Courtesies data if competency_score exists
// Check if 'user_details' and 'competency_score' exist before accessing 'Courtesies'
const storeSpiderData = DataHandle.competency_score;

// data is currently in formn of object:{string}
// code bellow converts it into object:{number}
const numericData = {};
for (const key in storeSpiderData) {
  if (storeSpiderData.hasOwnProperty(key)) {
    const value = storeSpiderData[key].split(',').map(Number);
    numericData[key] = value.length === 1 ? value[0] : value;
  }
}

// Store in localStorage after converting to JSON
localStorage.setItem("SpiderData", JSON.stringify({ competency_score: numericData }));






    //localstorge use to store a powerWords and negitive words 
    const PowerWords = DataHandle.power_words_used;
    localStorage.setItem("PowerWords", JSON.stringify(PowerWords));
    console.log("power words: ", PowerWords);

    const WeekWords = DataHandle.week_words_used;
    localStorage.setItem("WeekWords", JSON.stringify(WeekWords));
    console.log("week words: ", WeekWords)
    //attmed score
    setsimulationData(DataHandle.scenarios_attempted);
    console.log("simulation data: ", simulationData);
  })


  return (
    <div>
      <div className="analytics-title">
        <div>
          <h1 className="analytics-ryt">Competencies</h1>
          <h3 className="analytics-ryt-subtitle">You demonstrate</h3>
        </div>

        <img src={arrows} alt="Arrows" className="analytics-arrows" />

        <div>
          <h1 className="analytics-left">Sentiments</h1>
          <h3 className="analytics-left-subtitle">You Trigger</h3>
        </div>
      </div>

      {/* Add the dropdown with options */}
      {/* <div className="analytics-dropdown-container">
        <select
          id="dropdown"
          // value={selectedOption}
          // onChange={handleOptionChange}
        >
          <option value="">Select an option</option>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </select>
      </div> */}

      <div className="analytics-image-section">
        {/* <div className="analytics-img-txt-ryt-side">
          <h3>Simulations Completed - {simulationData}</h3>
        </div> */}

        <div className="analytics-container">
          <img src={example} alt="Arrows" className="analytics-arrows" />
          <h3> Simulations Completed - {simulationData}</h3>
        </div>
      </div>

      {/* <div className="analytics-text-section">
        <div className="analytics-txt-ryt-side">
          <h3 className="analytics-txt-ryt-side-title">295</h3>
        </div>

        <div className="analytics-txt-left-side">
          <h3 className="analytics-text-left-side-title">
            Data Loading...
          </h3>
          <h5 className="analytics-text-left-side-subtitle">
            Title Loading...
          </h5>
        </div>
      </div> */}

      <div className="graph-section">
      <h3 className="graph-txt-left-side-title">
            Competency Strengths & Gaps
          </h3>
        <div className="graph-show-ryt">
         <SpiderChart />
        </div>

        {/* <div className="graph-txt-left-side">
       
        </div> */}
      </div>

      {/* <div className="bar-graph-section">
        <div className="bar-graph-show-ryt">
         <BarChart />
        </div>

        <div className="bar-graph-txt-left-side">
          <h3 className="bar-graph-txt-left-side-title">
           Graph Data Loading..
          </h3>
        </div>
      </div> */}

      <div className="senti_btn_box">
      {/* <button className="senti_btn" onClick={handleAnotherJadu}>
        Try Impact Page
      </button> */}
    </div>
    <div className="admin-cards">
<PersonalImpactindex />
</div>
    </div>
  );
}

export default AnalyticsScreen;
